import { FC } from "react"
import { Page } from "../components/general/Page"
import { VStack, Text, NativeBaseProvider } from "native-base"

const ErrorPage: FC = () => {
    return (
        <NativeBaseProvider>
            <Page>
                <div style={{ background: 'linear-gradient(to right, #6C4DDF, #9A82F1)', }}>
                    <VStack style={{ backgroundColor: 'linear-gradient(to right, #6C4DDF, #9A82F1)', }} py='8' w='full' alignItems='center' space='3'>
                        <Text fontFamily='Poppins' color='white' textAlign='center' fontSize='2xl' fontWeight='600'>
                            {`Oops!\n No deals to be found here`}
                        </Text>
                    </VStack>
                </div>
                <span style={{margin:'auto', fontSize:60}} role="img" >
                    {String.fromCodePoint(0X1F605)}
                </span>
            </Page>
        </NativeBaseProvider>
    )
}
export default ErrorPage