import { IProduct } from "../interfaces/IProduct";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import firestore from "./firebase";
import { COLLECTIONS } from "../helpers/constants";
import { isIOS } from "../helpers/utils";

export async function fetchProductsByIds(ids: string[]): Promise<IProduct[]> {
    const productsRef = collection(firestore, COLLECTIONS.PRODUCTS);
    const q = query(productsRef, where("UPC", 'in', ids));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
        return []
    }
    const products=querySnapshot.docs.map(doc => doc.data() as IProduct)
    const sortedProducts:IProduct[]=[]
    ids.forEach(id=>{
        const product=products.find(p=> p.UPC === id)
        if(product){
            sortedProducts.push(product)
        }
    })
    return sortedProducts
}

export async function openProductInMobileApp(id: string) {
    if (isIOS()) {
        try {
            const request = await fetch(`${process.env.REACT_APP_BASE_API_URL}/API/dynamic-links/product`, {
                method: 'POST',
                body: JSON.stringify({ productId: id }),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            const data = await request.json()
            if (request.status < 400) {
                window.location.href = data.shortLink
            }
        }
        catch (error: any) {
            console.log(error)
        }

    }
    else {
        window.location.href = 'https://apps.apple.com/us/app/shop-ditto/id1593850923';
    }
}