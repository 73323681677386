import ICreator from "../interfaces/ICreator";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import firestore from "./firebase";
import { COLLECTIONS } from "../helpers/constants";


export async function getCreatorInfo(slug: string): Promise<ICreator | null> {
    const citiesRef = collection(firestore, COLLECTIONS.CREATORS);
    const q = query(citiesRef, where("slug", "==", slug), limit(1));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
        throw Error('Creator not found')
    }
    return querySnapshot.docs[0].data() as ICreator
}