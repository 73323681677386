const COLORS = {
    TEXT: {
        STANDARD: '#2C294D',
        LIGHT: '#FFFFFF',
        PRIMARY: '#6C4DDF'
    },
    BACKGROUND: {
        PRIMARY: '#6C4DDF',
        PRIMARY_LIGHT:'#E8E1FF'
    }
}
export default COLORS