import React from 'react';
import { NativeBaseProvider, extendTheme } from "native-base";
import { QueryClient, QueryClientProvider } from 'react-query';
import COLORS from './themes/light/colors';
import { Outlet } from "react-router-dom";

const queryClient = new QueryClient()
const lightTheme = extendTheme({
  components: {
    VStack: {
      variants: {
        primary: {
          backgroundColor: COLORS.BACKGROUND.PRIMARY
        }
      }
    },
    Box: {
      variants: {
        primary: {
          backgroundColor: COLORS.BACKGROUND.PRIMARY_LIGHT
        }
      }
    },
    Text: {
      defaultProps: {
        variant: 'standard'
      },
      baseStyle: {
        fontFamily: 'Poppins',
        fontWeight: '400'
      },
      variants: {
        standard: {
          color: COLORS.TEXT.STANDARD
        },
        light:{
          color:COLORS.TEXT.LIGHT
        },
        primary:{
          color:COLORS.TEXT.PRIMARY
        }
      }
    }
  },
  fontConfig: {
    Poppins: {
      100: {
        normal: "Poppins",
      },
      200: {
        normal: "Poppins"
      },
      300: {
        normal: "Poppins",
      },
      400: {
        normal: "Poppins"
      },
      500: {
        normal: "Poppins",
      },
      600: {
        normal: "Poppins"
      },
    }
  },
  fonts: {
    body: "Poppins"
  }
})
function App() {
  return (
    <NativeBaseProvider theme={lightTheme}>
      <QueryClientProvider client={queryClient}>
          <Outlet/>
      </QueryClientProvider>
    </NativeBaseProvider>
  );
}

export default App;
