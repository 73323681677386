import { Box, Center, Spinner } from 'native-base';
import React, { ReactNode } from 'react';
import { FC } from "react";


interface Props {
    children: ReactNode,
    isLoading?: boolean
}
export const Page: FC<Props> = ({ children, isLoading }) => {
    const { innerWidth, innerHeight, outerHeight } = window;
    return (
        <Box minH={innerHeight} bg='white'>
            <Box safeAreaTop={-100} w={innerWidth} maxW={500} mx='auto' >
                {!isLoading && children}
                {isLoading &&
                    <Center height={innerHeight}>
                        <Spinner />
                    </Center>
                }
            </Box>
        </Box>
    )
}