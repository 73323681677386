import React, { useEffect } from 'react';
import { FC } from "react";
import { useQuery } from 'react-query';
import { getCreatorInfo } from '../services/creators';
import { Page } from '../components/general/Page';
import { fetchProductsByIds } from '../services/products';
import { Avatar, Button, HStack, Text, VStack } from 'native-base';
import { FBIC, InstaIC, TikTokIC, YoutubeIC } from '../icons/Logos';
import DealCard from '../components/deal-card/DealCard';
import { chuckArray } from '../helpers/utils';
import FooterStamp from '../components/footer-stamp/FooterStamp';
import { useNavigate, useParams } from 'react-router-dom';



export const CreatorPage: FC = () => {
    const navigate = useNavigate()
    const { slug } = useParams()
    const { data: creator, error: creatorError } = useQuery({
        queryFn: () => getCreatorInfo(slug ?? ''),
        queryKey: slug,
        enabled: !!slug,
        retry: false
    })
    const { data: products, isLoading: productsLoading } = useQuery({
        queryFn: () => fetchProductsByIds(creator?.productIds ?? []),
        queryKey: creator?.productIds?.toString() ?? '',
        enabled: !!creator,
        retry: false
    })
    const productDeals = chuckArray(products ?? [], 2)

    useEffect(() => {
        if (!!creatorError) {
            navigate('/404')
        }
    }, [creatorError])


    return (
        <Page isLoading={!creator}>
            <div className='gradient-background'>
                <VStack pt='2' w='full' alignItems='center' space='2'>
                    <Avatar w='100px' h='100px' source={{ uri: creator?.avatarImage }} />
                    <VStack w='full' alignItems='center' space='1'>
                        <VStack space='0.5'>
                            <Text fontWeight='600' textAlign='center'>
                                {creator?.name}
                            </Text>
                            <Text textAlign='center'>
                                {creator?.description}
                            </Text>
                        </VStack>
                        <HStack space='4' w='full' justifyContent='center'>
                            {
                                !!creator?.instagramURL &&
                                <Button m='0' onPress={() => window.location.href = creator.instagramURL} variant='ghost'  >
                                    <InstaIC />
                                </Button>
                            }
                            {
                                !!creator?.tiktokURL &&
                                <Button onPress={() => window.location.href = creator.tiktokURL} variant='ghost'  >
                                    <TikTokIC />
                                </Button>
                            }
                            {
                                !!creator?.youtubeURL &&
                                <Button onPress={() => window.location.href = creator.youtubeURL} variant='ghost'  >
                                    <YoutubeIC />
                                </Button>
                            }
                            {
                                !!creator?.facebookURL &&
                                <Button onPress={() => window.location.href = creator.facebookURL} variant='ghost'  >
                                    <FBIC />
                                </Button>
                            }
                        </HStack>
                    </VStack>
                </VStack>
            </div>
            <VStack borderBottomLeftRadius='12px' borderBottomRightRadius='12px' py='2' variant='primary' alignItems='center'>
                <Text fontWeight='600' variant='light'>{creator?.message}</Text>
            </VStack>
            <VStack mt='6' space='5' px='5'>
                {
                    productDeals.map(deals => (
                        <HStack justifyContent='space-between' >
                            {
                                deals.map(deal => (
                                    <DealCard product={deal} />
                                ))
                            }
                        </HStack>
                    ))
                }
            </VStack>
            {!productsLoading && <FooterStamp />}
        </Page>
    )

}