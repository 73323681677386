import { VStack, Text } from "native-base"
import { FC } from "react"
import { DittoIC } from "../../icons/Logos"

const FooterStamp:FC=()=>{

    return(
        <VStack safeAreaBottom my='4' space='2' alignItems='center' mx='auto'>
            <Text fontWeight='600' color='#ACBFDC'>Powered By</Text>
            <DittoIC />
        </VStack>
    )
}

export default FooterStamp