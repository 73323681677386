import { FC } from "react";
import { IProduct } from "../../interfaces/IProduct";
import { Box, HStack, Image, Pressable, Text, VStack } from "native-base";
import { getDiscountPercent } from "../../helpers/utils";
import { openProductInMobileApp } from "../../services/products";
import Price from "../price/Price";

interface Props {
    product: IProduct
}
const DealCard: FC<Props> = ({ product }) => {
    return (
        <button onClick={() => openProductInMobileApp(product.UPC)} style={{ width: 164, border:'none', background:'transparent' }}>
            <VStack >
                {
                    !product.videos?.length ?
                        <Image h='164px' w='164px' borderRadius='8px' source={{ uri: product.photos[0] }} />
                        :
                        <video style={{ borderRadius: 8 }} autoPlay muted playsInline loop>
                            <source src={product.videos[0]} type="video/mp4" />
                        </video>
                }
                <VStack mt='4'>
                    <Text fontWeight='600'>{product.brand}</Text>
                    <Text >{product.productName}</Text>
                </VStack>
                <HStack alignItems='center' mt='1'>
                    <Price price={product.dittoPrice} />
                    <Box rounded='sm' ml='2' variant='primary' px='1'>
                        <Text fontWeight='600' variant='primary'>{`Save ${getDiscountPercent(product)}%`}</Text>
                    </Box>
                </HStack>
            </VStack>
        </button>
    )
}

export default DealCard