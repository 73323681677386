import { IProduct } from "../interfaces/IProduct";

export const getDiscountPercent = (
    product: IProduct
): number => {
    let discount = product.MSRP - (product.dittoPrice || 0);
    return Math.round((discount / product.MSRP) * 100);
};

export function chuckArray<Type>(array: Type[], chunkSize: number): Type[][] {
    const chunks: Type[][] = []
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        chunks.push(chunk)
    }
    return chunks
}

export function isIOS(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }