import { HStack, Text } from "native-base"
import { FC } from "react"

interface Props {
    price: number
}
const Price: FC<Props> = ({ price }) => {
    const priceText = price.toFixed(2)
    const pricePieces = priceText.split('.')
    return (
        <HStack>
            <Text variant='primary' fontSize='10px'>$</Text>
            <Text fontWeight='600' variant='primary'>{pricePieces[0]}</Text>
            <Text variant='primary' fontSize='10px'>{`.${pricePieces[1]}`}</Text>
        </HStack>
    )
}
export default Price